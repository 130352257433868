import React from 'react'
import classnames from 'classnames'

import styles from './style.module.scss'

interface InputProps {
  value?: string;
  onChange?: (newval: string) => void;
  placeholder?: string;
  type: string
}

export default class Input extends React.PureComponent<InputProps> {
  static defaultProps = {
    value: '',
    onChange () {},
    placeholder: '',
    type: 'text'
  }

  private onInputChange = (e) => {
    const { onChange } = this.props
    onChange && onChange(e.currentTarget.value)
  }

  render () {
    const inputClassname = classnames({
      [styles.input]: true
    })
    return (
      <input type={this.props.type} className={inputClassname} placeholder={this.props.placeholder}
        value={this.props.value} onChange={this.onInputChange}
      />
    )
  }
}
