import React from 'react'
import axios from 'axios'
import Toastify from 'toastify-js'

import { LOCAL_STORAGE_KEY_SESSIONKEY } from '@/index'
import { withRouter } from 'react-router'

interface Props {
  match: any
  location: any
  history: any
}

class TokenPage extends React.Component<Props, any> {
  state = {
    token: {}
  }

  public constructor (props) {
    super(props)
    this.requestToken()
  }

  private requestToken = async () => {
    const params = new URLSearchParams(this.props.location.search)
    try {
      if (params.get('appId')) {
        const resp = await axios.post('/api/auth/token', {
          appId: params.get('appId')
        })
        this.setState({
          token: resp.data
        })
        const { redirectUrl, appToken } = resp.data
        document.location.href = `${redirectUrl}?token=${appToken}`
      } else {
        const resp = await axios.get('/api/auth/auth')
        Toastify({
          text: 'Login Success!',
          duration: 3000,
          close: true,
          gravity: top,
          positionLeft: false,
          backgroundColor: '#00c164',
          stopOnFocus: true
        }).showToast()
        document.location.href = `https://sso.codetector.cn/?session=${resp.data.SessionKey}`
      }
    } catch (err) {
      if (err.request.status === 401) {
        this.props.history.push('/login' + this.props.location.search)
        localStorage.removeItem(LOCAL_STORAGE_KEY_SESSIONKEY)
      } else if (err.request.status === 400) {
        this.props.history.push('/login')
      }
    }
  }

  public render () {
    return (
      <div>
        <code>{JSON.stringify(this.state.token)}</code>
      </div>
    )
  }
}

export default withRouter(TokenPage)
