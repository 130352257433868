import React from 'react'
import classnames from 'classnames'

import styles from './style.module.scss'

interface ButtonProps {
  children?: any
  type: string | undefined
  onClick: (event) => void
}

export default class Button extends React.PureComponent<ButtonProps> {
  static defaultProps = {
    children: null,
    type: 'default',
    onClick: (_) => {}
  }

  public render () {
    const { type } = this.props

    const buttonClass = classnames({
      [styles.button]: true,
      [styles.primary]: type === 'primary'
    })

    return (
      <button
        type="button"
        className={buttonClass}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </button>
    )
  }
}
